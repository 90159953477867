var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.localData,"loading":_vm.loading,"hide-default-footer":"","show-expand":"","disable-pagination":"","id":"UnmatchedCommissionsTable","sort-by":_vm.sort,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sort=$event},"update:sort-by":function($event){_vm.sort=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.producer",fn:function(ref){
var item = ref.item;
return [_c('producer-assignment-select',{attrs:{"commission":item,"autosave":true}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('reason-code-column',{attrs:{"item":item}})]}},{key:"item.carrier",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"carrier"},[_vm._v(" "+_vm._s(item.carrier)+" ")])]}},{key:"item.ivans_status",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.ivans_status === 'pending')?_c('div',[_vm._v(" Pending ")]):(item.ivans_status === 'received')?_c('div',[_vm._v(" Downloaded ")]):_c('div',[_vm._v(" Manual ")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"extendedRowContainer d-flex"},[_c('div',{staticClass:"flex-grow-1"},[_c('table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"expandedTitle"},[_vm._v("LOB")]),_c('td',{staticClass:"expandedValue"},[_vm._v(_vm._s(item.lob))])]),_c('tr',[_c('td',{staticClass:"expandedTitle"},[_vm._v("PREMIUM")]),_c('td',{staticClass:"expandedValue"},[_vm._v(_vm._s(item.premium))])]),_c('tr',[_c('td',{staticClass:"expandedTitle"},[_vm._v("TERM")]),_c('td',{staticClass:"expandedValue"},[_vm._v(_vm._s(item.terms)+" Months")])])])])]),_c('div',{staticClass:"flex-grow-1"},[_c('table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"expandedTitle"},[_vm._v("BASE AMOUNT")]),_c('td',{staticClass:"expandedValue"},[_vm._v(_vm._s(item.agency_commissions))])]),(_vm.hasAggregator)?_c('tr',[_vm._v("   ")]):_vm._e(),(_vm.hasAggregator)?_c('tr',[_vm._v("   ")]):_vm._e()])])])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }