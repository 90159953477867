<template>
  <div>
    <div id="statementContentHeaderContainer">
      <div class="d-flex justify-space-between">
        <h3 id="statementContentContainerTitle">
          <span class="stepNumber">{{ '0' + activeStep }}</span>
          Review Unassigned Commissions
        </h3>
        <div>
          <approve-unmatched-button @approved="completed" />
        </div>
      </div>

      <div class="flex-grow-1" id="filterPanel">
        <div class="d-flex flex-wrap">
          <div class="flex-grow-1 mr-3 " style="max-width: 250px">
            <span class="filterSelectTitle">Report For:</span>

            <div>
              <v-col class="mt-3 pl-0">
                <v-icon class="ba-primary--text" style="margin-right:10px;">
                  {{ icons.mdiAccount }}
                </v-icon>

                <span class="filterText">Unassigned Commissions</span>
              </v-col>
            </div>
          </div>
          <div class='flex-grow-1 mr-3 ' style='max-width: 250px'>
            <span class='filterSelectTitle'>Pay Period:</span>
            <wizard-date-picker @goToStep='goToStep'/>
          </div>
<!--          <div class="flex-grow-1" style="max-width: 250px">-->
<!--            <span class="filterSelectTitle">Pay Period:</span>-->

<!--            <div>-->
<!--              <v-col class="mt-3 pl-0">-->
<!--                <v-icon class="ba-primary&#45;&#45;text" style="margin-right:10px;">-->
<!--                  {{ icons.mdiCalendar }}-->
<!--                </v-icon>-->

<!--                <span class="filterText">{{ month }} {{ year }}</span>-->
<!--              </v-col>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>

    <div id="tablePanel">
      <unmatched-commissions-table />
    </div>
  </div>
</template>

<script>
import { mdiCalendar, mdiAccount } from '@mdi/js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import UnmatchedCommissionsTable from './unmatched/UnmatchedCommissionsTable'
import ApproveUnmatchedButton from './unmatched/ApproveUnmatchedButton.vue'
import WizardDatePicker
  from '@/pages/commissions/commission-statements/wizard/steps/components/WizardDatePicker.vue'

export default {
  props: ['activeStep'],
  components: {
    WizardDatePicker,
    UnmatchedCommissionsTable,
    ApproveUnmatchedButton,
  },
  data() {
    return {
      icons: {
        mdiCalendar,
        mdiAccount,
      },
      loading: {
        unmatched: true,
      },
    }
  },
  methods: {
    ...mapActions({
      loadUnmatchedCommissions: 'commissions/unmatched/loadUnmatchedCommissions',
      updateAssignments: 'commissions/unmatched/updateAssignments',
      loadProducers: 'commissions/producers/loadProducers',
    }),
    ...mapMutations({
      setStepsStatuses: 'commissions/monthlyReview/setStepsStatuses',
    }),
    toMonthName(monthNumber) {
      const date = new Date()

      date.setFullYear(2023,parseInt(monthNumber) - 1,1);

      return date.toLocaleString('en-US', {
        month: 'long',
      })
    },
    completed() {
      this.$api.commissionMonthlyReview
        .completeStep({
          date: this.date,
          step: 'unmatched',
        })
        .then(({ data }) => {
          this.setStepsStatuses(data)
          this.$emit('nextStep')
        })
    },
    goToStep(step) {
      this.$debug.info('wizard emitted goToStep, relaying on unmatched view', step)
      this.$emit('goToStep', step)
    },
  },
  mounted() {
    this.loadProducers({
      date: this.date,
    }).then(() => {
      this.loadUnmatchedCommissions({
        date: this.date,
      }).then(() => {
        this.loading.unmatched = false
      })
    })
  },
  computed: {
    ...mapGetters({
      currentStep: 'commissions/monthlyReview/getCurrentStep',
    }),
    date() {
      return this.$route.params.year + '-' + this.$route.params.month
    },
    year() {
      return this.$route.params.year
    },
    month() {
      return this.toMonthName(this.$route.params.month)
    },
  },
}
</script>

<style lang="scss" scoped>
#statementContentHeaderContainer {
  background: #fff;
  padding: 24px 32px 16px 32px;
  margin-bottom: 2px;
}

#filterPanel {
  margin-top: 32px;
}

#tablePanel {
  margin-bottom: 2px;
}

.filterSelectTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3541;
  letter-spacing: 0.5px;
}

.filterText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3541;
}
</style>
