<template>
    <v-select
      :append-outer-icon='iconToShow'
      :value='value'
      :items='producerSelectList'
      item-text='display'
      dense
      item-value='value'
      label='Select Producer'
      single-line
      @change='valueChanged'
      style='max-width: 250px'
      ref='selector'
      :loading='loading.reassign'
      hide-details
    >
      <template v-slot:item='{ item }'>
        <div>
          <div>
            {{ item.display }}
          </div>
        </div>
      </template>
    </v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mdiCheckCircleOutline } from '@mdi/js'

export default {
  props: [
    'commission',
    'autosave',
  ],
  data() {
    return {
      value: null,
      loading: {
        reassign: false,
      },
      icons: {
        mdiCheckCircleOutline,
      },
      iconToShow: null,
    }
  },
  methods: {
    ...mapActions({
      setAssignment: 'commissions/unmatched/setAssignment',
      refreshStepsStatuses: 'commissions/monthlyReview/refreshStepsStatuses',
      refreshUnmatchedCommissions: 'commissions/unmatched/refreshUnmatchedCommissions',
    }),
    valueChanged(value) {
      if (this.autosave) {
        this.loading.reassign = true
        this.$api.commissions.updateUser({
          commission: this.commission.id,
          user: value,
        }).then(() => {
          this.$emit('commissionReassigned')
          this.loading.reassign = false
          this.iconToShow = this.icons.mdiCheckCircleOutline
          this.refreshUnmatchedCommissions()
        })
      } else {
        this.setAssignment({
          commissionId: this.commission.id,
          assignment: value,
        })
      }
    },
  },
  computed: {
    ...mapGetters({
      producers: 'commissions/producers/getProducers',
      assignments: 'commissions/unmatched/getAssignments',
    }),
    producerSelectList() {
      return this.producers.map(producer => {
        return {
          value: producer.id,
          display: producer.first_name + ' ' + producer.last_name,
        }
      })
    },
  },
  mounted() {
    if (this.commission.user_id) {
      this.value = this.commission.user_id
    }
  },
}
</script>

<style></style>
