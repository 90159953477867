<template>
  <v-data-table
    :headers="headers"
    :items="localData"
    :loading="loading"
    hide-default-footer
    show-expand
    disable-pagination
    id="UnmatchedCommissionsTable"
    :sort-by.sync='sort'
    :sort-desc.sync='sortDesc'
  >
    <template v-slot:item.producer="{ item }">
      <producer-assignment-select :commission="item" :autosave='true' />
    </template>

    <template v-slot:item.type='{item}'>
      <reason-code-column :item='item'/>
    </template>

    <template v-slot:item.carrier="{ item }">
      <div class="carrier">
        {{ item.carrier}}
      </div>
    </template>

    <template v-slot:item.ivans_status="{ item }">
      <div>
        <div v-if="item.ivans_status === 'pending'">
          Pending
        </div>

        <div v-else-if="item.ivans_status === 'received'">
          Downloaded
        </div>

        <div v-else>
          Manual
        </div>
      </div>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="extendedRowContainer d-flex">
          <div class="flex-grow-1">
            <table>
              <tbody>
              <!--  <tr>
                  <td class="expandedTitle">SOLD DATE</td>
                  <td class="expandedValue">{{ item.sold_date }}</td>
                </tr> -->
                <tr>
                  <td class="expandedTitle">LOB</td>
                  <td class="expandedValue">{{ item.lob }}</td>
                </tr>
                <tr>
                  <td class="expandedTitle">PREMIUM</td>
                  <td class="expandedValue">{{ item.premium }}</td>
                </tr>
                <tr>
                  <td class="expandedTitle">TERM</td>
                  <td class="expandedValue">{{ item.terms }} Months</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex-grow-1">
            <table>
              <tbody>
                <tr>
                  <td class="expandedTitle">BASE AMOUNT</td>
                  <td class="expandedValue">{{ item.agency_commissions }}</td>
                </tr>
                <tr v-if="hasAggregator">
                  &nbsp;
                </tr>
                <tr v-if="hasAggregator">
                  &nbsp;
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProducerAssignmentSelect from './ProducerAssignmentSelect.vue'
import ReasonCodeColumn
  from '@/pages/commissions/commission-statements/wizard/steps/unmatched/components/ReasonCodeColumn.vue'

export default {
  props: ['loading'],
  components: { ReasonCodeColumn, ProducerAssignmentSelect },
  data() {
    return {
      initiallyLoaded: false,
      localData:[],
      sort: undefined,
      sortDesc: undefined,
    }
  },
  mounted() {
    this.clearUnmatchedSort();
  },
  methods: {
    ...mapActions({
      setUnmatchedSort: 'commissions/unmatched/setSort',
      setUnmatchedSortDesc: 'commissions/unmatched/setSortDesc',
      clearUnmatchedSort: 'commissions/unmatched/clearSort',
    }),
  },
  watch: {
    tableData: {
      handler() {
        if (this.initiallyLoaded === false) {
          this.initiallyLoaded = true
          this.localData = this.tableData
        }
      },
      deep: true,
    },
    sort: {
      handler() {
        this.setUnmatchedSort(this.sort)
      },
      deep: true,
    },
    sortDesc: {
      handler() {
        this.setUnmatchedSortDesc(this.sortDesc)
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      tableData: 'commissions/unmatched/getCommissionsTable',
      producers: 'commissions/producers/getProducers',
      currentUser: 'getCurrentUser',
    }),
    hasAggregator() {
      return this.currentUser?.related?.agency?.data?.attributes?.has_aggregator
    },
    headers() {
      let ivans = []

      if (this.currentUser?.related?.agency?.data?.attributes?.ivans_supported) {
        ivans = [
          {
            text: 'Ivans Status',
            align: 'start',
            sortable: false,
            value: 'ivans_status',
            class: 'columnStatus',
            cellClass: 'columnStatus',
          },
        ]
      }

      let output = [
        {
          text: 'Effective',
          align: 'start',
          sortable: true,
          value: 'effective_date',
          class: 'columnEffectiveDate',
          cellClass: 'columnEffectiveDate',
        },
        {
          text: 'Type',
          align: 'start',
          sortable: true,
          value: 'type',
          class: 'columnType',
          cellClass: 'columnType',
        },
        {
          text: 'Insured',
          align: 'start',
          sortable: true,
          value: 'insured',
          class: 'columnInsured',
          cellClass: 'columnInsured',
        },
        {
          text: 'Policy Number',
          align: 'start',
          sortable: false,
          value: 'policy',
          class: 'columnPolicy',
          cellClass: 'columnPolicy',
        },
        ...ivans,
        {
          text: 'Carrier',
          align: 'start',
          sortable: true,
          value: 'carrier',
          class: 'columnCarrier',
          cellClass: 'columnCarrier',
        },
        {
          text: 'Agency Pay',
          align: 'start',
          sortable: false,
          value: 'agency_commissions',
          class: 'columnAgencyCommission',
          cellClass: 'columnAgencyCommission',
        },
        {
          text: 'Producer',
          align: 'start',
          sortable: false,
          value: 'producer',
          class: 'columnProducerAssign',
          cellClass: 'columnProducerAssign',
        },
        {
          text: '',
          value: 'data-table-expand',
          cellClass: 'columnExpand',
        },
      ]

      return output
    },
  },
}
</script>

<style lang="scss">
#UnmatchedCommissionsTable {
  padding: 0;

  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: rgba(94, 86, 105, 0.87);
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgba(94, 86, 105, 0.87);
    opacity: 1;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(94, 86, 105, 0.87);
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(94, 86, 105, 0.87);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(94, 86, 105, 0.87);
  }

  ::placeholder {
    /* Most modern browsers support this now. */
    color: rgba(94, 86, 105, 0.87);
  }

  td {
    padding: 16px 16px 16px 32px;
    border: none;
    background: white;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  th {
    padding: 16px 24px 16px 32px;
    background: #fff !important;
    border: none;

    /* Light/Components/Table Header */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */

    text-transform: uppercase;

    /* Light/Text/Primary */

    color: #3a3541;

    vertical-align: top;
  }

  .columnProducerRate {
    min-width: 20px;
    max-width: 140px;
  }

  .columnType {
    min-width: 20px;
    max-width: 100px;
  }

  .columnInsured {
    min-width: 120px;
  }

  .columnPlan {
    min-width: 120px;
    max-width: 140px;
  }

  .columnProducerCommission {
    min-width: 20px;
    max-width: 130px;
  }

  .columnAgencyCommission {
    min-width: 120px;
    max-width: 120px;
  }

  th.columnEffectiveDate{
    white-space: nowrap;
  }

  th.columnType{
    white-space: nowrap;
  }

  th.columnLob{
    white-space: nowrap;
  }

  th.columnInsured{
    white-space: nowrap;
  }

  th.columnCarrier{
    white-space: nowrap;
  }

  .extendedRowContainer {
    background: #f1f1f2;
    width: 100%;
    table {
      background: none;
      border: none;
      width: 100%;

      .expandedValue {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #3a3541;

        border: none;
        background: none !important;
        width: 50%;

        padding-top: 8px;
        padding-bottom: 8px;
      }

      .expandedTitle {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #3a3541;

        border: none;
        background: none !important;
        width: 50%;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }

  .columnExpand {
    width: 40px;
    padding-left: 15px;
  }
}

.carrier{
  text-transform: capitalize;
}
</style>
