<template>
  <div>
    <span>
      <v-tooltip top v-if='reasonCodeDescription'>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ item.type }}
          </span>
        </template>
        <span>{{ reasonCodeDescription }}</span>
      </v-tooltip>
      <span v-else>
        {{ item.type }}
      </span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: { },
  props: ['item'],
  methods: {
  },
  computed: {
    ...mapGetters({
      enums: 'enums/getEnums',
    }),
    reasonCodeDescription(){
      let reason = this.enums.commission_reason_codes[this.item.type]

        if(reason) {
        return reason.display_value
        }
    }
  },
}
</script>

<style lang='scss' scoped>

</style>
