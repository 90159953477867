<template>
  <v-menu
    ref="menu"
    v-model="monthMenu"
    :close-on-content-click="false"
    :return-value.sync="filterDate"
    transition="scale-transition"
    max-width="290px"
    min-width="auto"
    id="statementDatePicker"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="filterDateFormatted"
        :prepend-icon="icons.mdiCalendar"
        readonly
        hide-details
        v-bind="attrs"
        v-on="on"
        id="statementDatePickerInput"
        class='mt-3'
      ></v-text-field>
    </template>
    <v-date-picker v-model="filterDate" type="month" no-title scrollable @change="$refs.menu.save(filterDate)">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="monthMenu = false"> Cancel</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { mdiAccount, mdiCalendar, mdiCheck, mdiClose, mdiCurrencyUsd, mdiPercent } from '@mdi/js'

export default {
  data() {
    return {
      filterDate: null,
      monthMenu: false,
      icons: {
        mdiCalendar,
        mdiAccount,
        mdiCurrencyUsd,
        mdiPercent,
        mdiClose,
        mdiCheck,
      },
      loading: {
        filterDate: true,
      },
    }
  },
  mounted() {
    this.filterDate = this.date
    this.loading.filterDate = false
  },
  computed: {
    filterDateFormatted() {
      return this.$moment(this.filterDate).format('MMMM YYYY')
    },
    filterMonthName() {
      return this.$moment(this.filterDate).format('MMMM')
    },
    date() {
      return this.$route.params.year + '-' + this.$route.params.month
    },
  },
  watch: {
    filterDate(newValue, oldValue) {
      if(newValue !== oldValue && oldValue !== null) {
        //todo RESET WIZARD PROGRESS

        this.$router
          .push({
            name: 'commission-statement.entry',
            params: {
              month: this.$moment(newValue).format('MM'),
              year: this.$moment(newValue).format('YYYY'),
            },
          })
          .catch(() => {
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep #statementDatePickerInput {
  color: #3a3541;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  padding-bottom: 20px;



}

::v-deep .v-input__prepend-outer {
  .v-icon {
    color: $ba-primary;
    margin-top: -8px;

    .v-icon__svg {
      height: 30px;
      width: 30px;
    }
  }
}
</style>
